.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.position-relative {
  position: relative;
}

// ROUTER
#full-component-router~* {
  // position: absolute;
  // height: 100%;
  // width: 100%;
  z-index: 1;
}

// .mat-raised-button,
// .mat-button {
//   border-radius: 5px !important;
//   // transition: box-shadow 1s cubic-bezier(0.075, 0.82, 0.165, 1), transform 1s cubic-bezier(0.075, 0.82, 0.165, 1) !important;
// }

// .mat-raised-button:hover,
// .mat-button:hover {
//   transform: translateY(-2px);
//   // box-shadow: 0 0px 10px rgba(0, 0, 0, .25);
// }

// .mat-stroked-button {
//   border-radius: 3px !important;
// }

// MARK: - COLORS - DO NOT CHANGE THE ORDER
.green {
  color: rgb(25, 151, 25);
}

.blue {
  color: rgb(30, 144, 255);
}

.yellow {
  color: #ffb62a;
}

.red {
  color: rgb(192, 24, 24);

  .dark & {
    color: rgb(211, 26, 26);
  }
}

.yellow-bg {
  background: gold !important;
}

.blue-bg {
  background: #0051ff !important;
  color: white !important;
}

.red-bg {
  background: #a41726 !important;
  color: white !important;
}

// Font weight
.bold {
  font-weight: 500;
}

.boldest {
  font-weight: bold;
}

// MARK: - Text Decoration
.dotted-underline {
  text-decoration: underline dotted rgba($color: black, $alpha: 0.7);

  &:hover {
    text-decoration-color: rgba($color: black, $alpha: 0.3);
  }

  .dark & {
    text-decoration-color: rgba($color: white, $alpha: 0.6);

    &:hover {
      text-decoration-color: rgba($color: white, $alpha: 0.3);
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
}

// MARK: - Cursor
.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

// MARK: - Line height
.line-height-1_0 {
  line-height: 1.0;
}

.line-height-1_2 {
  line-height: 1.2;
}

.line-height-1_3 {
  line-height: 1.3;
}

.line-height-1_4 {
  line-height: 1.4;
}

// MARK: - TABLE CUSTOMIZATIONS
.mat-table {

  border-radius: 8px;

  [class*="-row"]:first-child {
    border-radius: 8px 8px 0 0;
  }

  [class*="-row"]:last-child {
    border-radius: 0 0 8px 8px;
  }

  [class*="mat-column"]:not(:first-child) {
    padding: 4px 0 4px 8px;
  }

  @media screen and (max-width: 760px) {
    [class*="mat-column"]:not(.mat-column-paginator) {
      min-width: 120px;
      max-width: 120px;
    }

    .mat-header-row,
    .mat-row {
      width: fit-content !important;
      min-width: 100%;
    }
  }

  &.horizontally-scrollable-table {

    .mat-header-row,
    .mat-row {
      width: fit-content !important;
      min-width: 100%;
    }
  }

  .mat-header-row {
    line-height: 1.2;
  }

  mat-row {

    .mat-column-actions button {
      visibility: hidden;
    }

    &:hover {
      &>.mat-column-actions button {
        visibility: visible;
      }
    }

    &:nth-child(even) {
      background: #fcfcfc;

      .dark & {
        background: rgba($color: black, $alpha: 0.1);
      }
    }
  }

  // More space in column for long numbers
  .mat-column-vin,
  .mat-column-truck_vin_number {
    flex: 0 0 15%;
  }

  .hoverable-border & mat-row,
  &.hoverable-border mat-row {
    border-top: 1px solid transparent;

    &:hover {
      border-color: $themecolor;
    }
  }

  mat-cell {
    line-height: 1.2;

    &.two-line-cell {
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;

      *:nth-child(2) {
        font-size: 0.8em;
      }
    }
  }
}

// MARK: MatTabGroup
.mat-tab-body {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

// SNACKBAR
.mat-simple-snackbar-action {
  color: $info;
}

.warn-snackbar {
  background: rgb(255, 153, 0);
  color: rgba(255, 255, 255, 0.85);

  .mat-simple-snackbar-action {
    color: white;
  }
}

.error-snackbar {
  background: rgb(230, 55, 64);
  color: rgba(255, 255, 255, 0.85);

  .mat-simple-snackbar-action {
    color: white;
  }
}

// NgxTimePicker and DatePicker

ngx-mat-timepicker .mat-form-field-flex {
  padding: 0 !important;
}

.mat-date-range-input-container {
  line-height: 25px;
}

// MODALS

.pm-modal .mat-dialog-content {
  overflow-x: hidden;
}

// MARK: mat-icon and mat-icon-button
button.mat-flat-button,
button.mat-raised-button,
button.mat-button {
  .mat-icon[inline] {
    // margin-top: -2px;
    font-size: 1.25em;
    vertical-align: top;
    padding-top: 0.5px;
  }

  .mat-icon[inline="inherit"] {
    font-size: inherit;
  }
}

.mat-icon-button.fix-icon-center .mat-button-wrapper {
  display: flex;
  justify-content: center;
}

.mat-icon-button.fix-icon-right .mat-button-wrapper {
  display: flex;
  justify-content: right;
}

// MARK: Buttons
.mat-raised-button,
.mat-flat-button,
.mat-button,
.mat-stroked-button {
  border-radius: 6px !important;
}

// MARK: MatDialog
.mat-dialog-actions {
  box-sizing: content-box !important;
}


// TEXT: unselectable
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


// MAT-CARD

.mat-card-header+.mat-card-content {

  &>mat-form-field,
  &>*>mat-form-field {
    margin-top: -12px;
  }
}

// MARK: Loading Button
.mat-loading {
  .mat-button-wrapper {
    visibility: hidden;
    transition: 0.1s;
  }

  .mat-progress-spinner,
  .mat-icon {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }

  .mat-icon {
    top: calc(50% - 12px);
  }
}

// Selects and Options
.no-checkbox-option {
  color: initial !important;

  .mat-pseudo-checkbox {
    display: none;
  }
}

ams-checkbox .mat-checkbox-layout {
  white-space: unset;
}
