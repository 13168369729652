@media print {

  html .page-wrapper {
    height: 100%;
  }

  body,
  .page-wrapper,
  .page-content {
    margin: 0 !important;
  }

  .mat-toolbar,
  .mat-sidenav,
  mat-paginator,
  button {
    display: none !important;
  }
}
